const actions = {
  changeWindow: (state, { payload }) => {
    return {
      ...state,
      ...payload,
    }
  },
}

function windowReducer(state, action) {
  switch (action.type) {
    case "changeWindow":
      return actions.changeWindow(state, action)
  }
}

export default windowReducer
