import { useContext, Context } from "react"
import { CartProvider, CartCtx } from "./Cart/Provider"
import { CustomerProvider, CustomerCtx } from "./Customer/Provider"
import { AppProvider, AppCtx } from "./App/Provider"
import { WindowProvider, WindowCtx } from "./Window/Provider"

import {
  IWindowParams,
  WithState,
  WithDispatch,
  ElasticObject,
} from "./index.d"

const useActions = <T extends WithDispatch<T>>(
  ctx: Context<T>,
  selected: string | string[]
) => {
  const { dispatch } = useContext<T>(ctx)

  if (Array.isArray(selected)) {
    return selected.reduce(
      (actions: ElasticObject, name: string) => ({
        ...actions,
        [name]: (payload: any) =>
          dispatch({
            type: name,
            payload,
          }),
      }),
      {}
    )
  }
  return {
    [selected]: (payload: any) =>
      dispatch({
        type: selected,
        payload,
      }),
  }
}

const useContextState = <T extends WithState<T>>(
  ctx: Context<T>,
  selected: string | string[] = "*"
) => {
  const { state } = useContext<T>(ctx)
  if (Array.isArray(selected)) {
    return selected.reduce(
      (states: ElasticObject, name: string) => ({
        ...states,
        [name]: state[name],
      }),
      {}
    )
  }
  return selected === "*"
    ? state
    : {
        [selected]: state[selected],
      }
}

export {
  useActions,
  useContextState,
  CartProvider,
  CartCtx,
  CustomerProvider,
  CustomerCtx,
  AppProvider,
  AppCtx,
  WindowProvider,
  WindowCtx,
}

export { IWindowParams }
