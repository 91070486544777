const actions = {
  loaded: (state, { payload }) => {
    return {
      imBusy: false,
    }
  },
  loading: (state, { payload }) => {
    return {
      imBusy: true,
    }
  },
}

function appReducer(state, action) {
  switch (action.type) {
    case "loaded":
      return actions.loaded(state, action)
    case "loading":
      return actions.loading(state, action)
  }
}

export default appReducer
